
/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face
{
    font-family: "wcHeavy";
    src: url('../../fonts/WCManoNegraBoldBta.ttf'); /* IE */
    src: local("WCManoNegraBoldBta"), url(../../fonts/WCManoNegraBoldBta.ttf) format("truetype"); /* non-IE */
}

/*Main Colors*/
$orange_color: #d66e14;
$orange_hov_color: #f77300;
$black_color: #171611;
$black_hov_color: #33322a;

.orange_text{
    color: #d66e14;
    font-weight: bold;
}

.text_objetivo{
    color: #d66e14;
    font-weight: bold;
    font-size: 24px;
    font-family: wcHeavy;
}

.green_text{
    color: #1e6500;
    font-weight: bold;
}

/****************
  WHATSAPP ICON
*****************/

.whatsIcon {
	position: fixed;
	bottom: 28px;
	width: 40px;
	height: 40px;
	left: 45px;
	background-color: #25D366;
	color: #FFF;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #424242;
	z-index: 999;
  }
  
  .whatsIcon:hover {
	background: #40ea37;
  }
  
  .whatsIcon img {
	width: 22px;
	margin-top: -12px;
  }

/****************
       NAV
*****************/

.navbar-toggler:focus{
    box-shadow: initial !important;
}

.nav_yao {

    background-color: #fff;


    @media (max-width: 600px) {
        .navbar-brand img {
            width: 180px;
        }
    }

    .navbar-collapse a{
        font-family: 'Raleway', sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: $black_color;
    }

    .dropdown-menu a{
        font-weight: 300;
    }

    .dropdown-toggle::after{
        color: $orange_color;
    }
    .dropdown-item.active, .dropdown-item:active{
        background-color: #e9ecef;
    }
}

@media (max-width: 1199px) {
    .nav_yao .navbar-collapse a {
       font-size: 12px;
    }
}

@media (max-width: 991px) {
    .nav_yao .navbar-collapse a {
       font-size: 15px;
    }
}

.carousel_home{
    margin-top: 6.5rem;
}

@media (max-width: 600px) {
    .carousel_home {
        margin-top: 4.6rem;
    }
    header.headerYaoWrapp{
        margin-top: 4.6rem !important;
    }
}

/****************
      HOME
*****************/
#homeParallax{
    background-image: url('../../img/background01.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    .section-title{
        margin-bottom: 50px;
    }

    .section-title h1{
        margin-top: 0;
        font-family: wcHeavy;
        color: $orange_color;
        text-transform: none;
        font-size: 45px;
        padding-top: 45px;
    }
    
    .descrip_text{
        margin-top: 30px;
        
        h2{
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 15px;
        }

        h2 i{
            margin-right: 10px;
        }

        p{
            font-size:20px;
            text-align: justify;
            background: rgba(255, 255, 255, 0.40);
            padding: 10px;
            font-family: 'Raleway', sans-serif;
        }
    }
}

/****************
   GALLERY HOME
*****************/

#galleryhome{
    padding-top: 45px;
    padding-bottom: 12px;

    .galle_ho img{
        margin-bottom: 30px;
    }
}



/****************
   BENEFICIOS
*****************/

#beneficios{
    padding-top: 45px;
    padding-bottom: 12px;
    
    .card_home_t {
        margin-bottom: 25px;
    }

    .color_card_a{
        background-color: $orange_color;
        color: #fff;
        border-left: 10px solid $black_color !important;
    }
    
    .color_card_b{
        background-color: $black_color;
        color: #fff;
        border-left: 10px solid #222222 !important;
    }
    .beneficios_title{
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        font-size: 35px;
        padding-bottom: 35px;
        text-align: center;
    }
    
}

/****************
  WELCOME TEXT
*****************/

#testimonial{
    background:#d66e14;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 45px;
    padding-bottom: 25px;
    position: relative;



    .thome{
        font-size: 60px;
        font-family: wcHeavy;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 767px) {
        .thome {
            font-size: 35px;
        }
    }
    @media (max-width: 560px) {
        .thome {
            font-size: 25px;
        }
    }
    
}

@media (max-width: 767px) {
    #testimonial {
        padding-top: 25px;
        padding-bottom: 15px;
    }

}


/****************
      VIDEO
*****************/
#videohome{

    padding-top: 10rem;
    font-family: 'Raleway', sans-serif;

    h2{
        font-weight: bold;
        font-size: 35px;
        
        padding-top: 35px;
    
        span{
            color: $orange_color;
        }

    }

    p{
        padding-top: 25px;
        padding-bottom: 15px;
    }

    .btn_unete{
        background-color: $orange_color;
        border: 0;
        padding: 12px 40px;
        font-size: 18px;
    }

    .btn_unete:hover{
        background-color: $orange_hov_color;
    }

    .btn_conoce{
        background-color: $black_color;
        border: 0;
        padding: 12px 40px;
        font-size: 18px;
    }

    .btn_conoce:hover{
        background-color: $black_hov_color;
    }
    
    @media (max-width: 991px) {
        .video_yao {
            margin-top: 30px;
        }
    }

    @media (max-width: 600px) {
        .btn_unete, .btn_conoce {
           padding: 12px 25px;
           font-size: 13px;
        }
    }

}

@media (max-width: 991px) {
    #videohome {
        padding-top: 2rem;
    }
}


/****************
     CALENDAR
*****************/

#calendarhome{
    margin-bottom: 50px;

    h3{
        margin-top: 65px;
        padding-bottom: 25px;
        font-family: wcHeavy;
        color: #d66e14;
        text-transform: none;
        font-size: 45px;
        
    }

    .fc-col-header {
		background-color: #d66e14;
	}

    thead .fc-scrollgrid-sync-inner a{
        color: #fff;
        text-decoration: none;
    }

    .fc-scrollgrid-sync-inner a{
        text-decoration: none;
        color: $black_color;
    }
    .fc-toolbar-chunk .fc-today-button{
        background-color: $orange_color;
        opacity: 1;
        border: 0;
    }
}

/****************
    ORIGENES
*****************/

header.headerYaoWrapp {
    padding-top: 8.5rem;
    padding-bottom: 6rem;
    text-align: center;
    color: #fff;
    margin-top: 6.5rem;

    .yao_heading {
        font-size: 60px;
        line-height: 3.25rem;
        margin-bottom: 2rem;
        font-family: wcHeavy;
    }

}

.head_back_a{
    background-image: url("../../img/header_yao/headeryao_01.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.head_back_b{
    background-image: url("../../img/header_yao/headeryao_02.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.head_back_c{
    background-image: url("../../img/header_yao/headeryao_03.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.head_back_d{
    background-image: url("../../img/header_yao/headeryao_04.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.head_back_e{
    background-image: url("../../img/header_yao/headeryao_05.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

.head_back_f{
    background-image: url("../../img/header_yao/headeryao_06.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
}

  @media (max-width: 767px) {
    header.headerYaoWrapp {
      padding-top: 5rem;
      padding-bottom: 1rem;
    }
    header.headerYaoWrapp .yao_heading {
      font-size: 38px;
      margin-bottom: 4rem;
    }
}


.bord_yao{
    border: 1px solid $orange_color !important;
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    margin-top: 40px;
    text-align: justify;
}
  
/****************
     SISTEMA
*****************/
#sistema{
    font-family: 'Raleway', sans-serif;

   h2{
    font-weight: bold;
    text-align: center;
   }

   p{
    font-size: 17px;
    text-align: center;
    padding-bottom: 15px;
   }

   h5{
    text-align: center;
   }

   .sistem_c{
    padding-top: 25px;
   }
}

/****************
     GRADOS
*****************/

#grados{

    .tabs_grados{

        .nav-link{
            color: #cfcfcf;
            font-family: 'Raleway', sans-serif;
        }
    
        .nav-link:hover{
            background-color: #e4e4e4;
            color: $black_color;
        }
    
        .nav-link.active{
            background-color: #e4e4e4 !important;
            color: $black_color !important;
        }
    
        .icon_animal{
            width: 25px;
        }
        @media (max-width: 767px) {
            .nav-link{
                font-size: 10px;
                padding: 6px;
            }
        }

    }

    h2{
        font-weight: bold;
        font-size: 23px;
        color: $orange_color;
        font-family: 'Raleway', sans-serif;
    }

    p{
        font-weight: 300;
        font-size: 20px;
        color: $black_color;

        .title_sub_anim{
            font-family: wcHeavy;
            font-size: 21px;
        }

        span{
            color: $orange_color;
        }
    }

    .accordion-button:not(.collapsed){
        background-color: $orange_color !important;
        color: white;
    }

    .accordion-button:focus{
        box-shadow: initial;
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

}


.escuelas_text{
    font-family: 'Raleway', sans-serif;

    padding-bottom: 15px;

    span{
        display: block;
        padding-top: 25px;
        color: $orange_color;
        font-family: wcHeavy;
        font-size: 23px;
    }


}


/****************
    GALERIA
*****************/
#gallery_yao {

    padding-top: 45px;
    padding-bottom: 12px;
    

    .galle_ho img{
        margin-bottom: 30px;
    }

    .active_gal{
        background-color: $orange_color !important;
        color: #fff !important;
    }

    .filter_list{

        text-align: center;
        padding-bottom: 30px;

        .list-group-item.active{
            background-color: $orange_color;
            border-color: $orange_color;
        }
    
        a{
            text-align: center;
        }
        @media (max-width: 767px) {
            a{
                font-size: 10px;
            }
        }
    }

    
}


/****************
    BIBLIOTECA
*****************/

.card_bib{
    border: 1px solid $orange_color !important;
    margin-bottom: 30px;
}

.card_bib .card-body {

    font-family: 'Raleway', sans-serif;
    border: 0px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
        
    img{
        float: right;
    }

    .card-title{
        color: $orange_color;
        font-weight: bold;
    }

    .card-link{
       display: table;
       margin-left: 0 !important;
       color: $black_color;
       font-weight: 100;
       line-height: 1.8rem;
       text-decoration: none;
       padding-left: 4px;
       padding-right: 4px;
       -webkit-border-radius: 25px;
       -moz-border-radius: 25px;
       border-radius: 25px;
        
    }

    .card-link:hover{
        color: #fff;
        background-color: $orange_color;
    }

}


/****************
    TESTIMONIALES
*****************/

#testimonials{
    margin-top: 10rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
    color: #fff;
    background-color: #f1ede6;

    h3{
        font-family: wcHeavy;
        color: #d66e14;
        text-transform: none;
        font-size: 45px;
        padding-top: 45px;
    }
    .testimonials_c{
        .owl-nav .owl-next, .owl-nav .owl-prev{
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 1px solid $orange_color !important;
            padding: 7px 17px !important;
            color: #fff !important;
            background-color: $orange_color;
          }
          
          .owl-nav [class*=owl-]:hover {
            background-color: $orange_hov_color !important;
            color: #fff !important;
          }
        
    }

    .testimonials_c .item {
        text-align: center;
        margin-bottom: 50px;
        -webkit-transform: scale3d(0.8, 0.8, 1);
        transform: scale3d(0.8, 0.8, 1);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .testimonials_c .owl-item img {
        max-width: 120px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin: 0 auto 17px;
    }
    
    .card_testim{
        font-family: 'Raleway', sans-serif;
       
        .card-title{
            color: $orange_color;
            font-weight: bold;
        }
        
        .card-text{
            color: $black_color;
        }
    
    }


    
}

@media (max-width: 991px) {
    #testimonials{
       margin-top: 4rem;
    }
}

/****************
    EVENTOS
*****************/
.event_card_b{
    .card-img-overlay{
        padding-left: 0 !important;
    }
    .text_overlay_ev{
        position: absolute;
        top: 18px;
        background: $orange_color;
        color: #fff;
        display: table;
        padding: 2px 11px;
        font-size: 15px;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }
}

.events_body{
    font-family: 'Raleway', sans-serif;

    .h5{
        color: $orange_color;
        font-weight: bold;
    }
   
    .events_body_a{
        color: $black_color;
    }
    
    .events_body_b{
        color: $black_color;
    }

    a{
        background-color: $orange_color !important;
        border: 0;
        padding: 9px 19px;
        font-size: 15px;
    }

    a:hover{
        background-color: $orange_hov_color !important;
    }
}

/****************
    LOGIN-REGISTER
*****************/

.btn_access{
    background-color: $black_color !important;
    border: 0 !important;
    border-radius: 0 !important;
    font-family: "Raleway", sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #fff !important;
    margin-top: -6px !important;

    .icon_access{
        color: #fff;
        padding-right: 5px;
        font-size: 13px;
    }
}

.btn_access:hover{
    background-color: $orange_color !important;
}


@media (max-width: 1199px) {
    .btn_access {
       font-size: 12px !important;
    }
}

@media (max-width: 991px) {
    .btn_access {
       font-size: 15px !important;
    }
}



.tabs_modal{
    font-family: "Raleway", sans-serif;

    .tabs_nav_access 
    {
        flex-direction: row !important;
        margin-top: 35px;
        
        .nav-item .active{
        background-color: #0083c1 !important;
        color: #fff !important; 
        }
    
    }


    .btn_login_b{
        background-color: #b2292e !important;
        border: 0 !important;
        margin-bottom: 30px;
        font-size: 15px;
        color: #fff;
    }
    
    .btn_login_b:hover{
        background-color: #ca292e !important;
    }
    
    .logo_admin{
        width: 190px;
    }
    
    .acc_text{
        text-align: center;
        font-size: 14px;
    
        a{
            color: #777;
            text-decoration: none;
    
        }
    
        a:hover{
            color: #0083c1;
        }
    
    }


}







/****************
  CONTACT
*****************/

#contact_yao{
    font-family: 'Raleway', sans-serif;

    .btn_submit_yao{
        background-color: $orange_color;
        border: 0;
        padding: 12px 30px;
        margin-bottom: 30px;
        font-size: 15px;
        color: #fff;
    }
    
    .btn_submit_yao:hover{
        background-color: $orange_hov_color;
    }
    
    .list_contact{
        margin-top: 30px;
        padding-left: 0;
        list-style: none;
    
        li{
            margin-bottom: 10px;
        }
    
        a{
           text-decoration: none;
           color: $black_color;
           font-weight: 300
        }
    
        a:hover{
            color: $orange_color;
        }
    
        svg{
            color: $black_color;
        }
    
    }
    
    .form_yao_c input, .form_yao_c textarea{
        border-radius: 0;
    }
    

}




/****************
   FOOTER
*****************/

footer{
    background-color: $black_color;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;

    img{
        width: 50px;
    }

    ul{
        font-size: 22px;
        justify-content: flex-end;

        .icon_footer{
            color: #6c757d;
        }

        .icon_footer:hover{
            color: #fff;
        }
    }

    @media (max-width: 767px) {
        p {
            text-align: center;
            padding-bottom: 20px;
        }

        ul{
            justify-content: center;
        }
    }

}

/****************
    NOT FOUND PAGE
*****************/

#notfound{
    .btn_return_h{
        background-color: $orange_color;
        border: 0;
        padding: 12px 30px;
        font-size: 18px;
    }

    .btn_return_h:hover{
        background-color: $orange_hov_color;
    }

    @media (max-width: 767px) {
        .btn_return_h{
            margin-top: -30px;
            margin-bottom: 30px;
            font-size: 12px;
        }
    }

}

